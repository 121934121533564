import React from 'react';
import { useKnowledgeBaseItem } from './useKnowledgeBaseItem';
import { LoadingIndicator, useSaveable } from '../../../toolympus/components/primitives';
import { useParams } from 'react-router-dom';
import { Blip, PageContent1x1, PageMainColumn, PageTitle, PageTitleLine, PageWrapper } from '../../Common/PageComponents';
import { DocumentTitle } from '../TitleEditor';
import { CurrentDocumentProvider } from '../DocumentsContext';
import { SpellbookEditor } from '../SpellbookEditor';

interface Props {
  
}

export const KnowledgeBaseItemPage = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const data = useKnowledgeBaseItem(id);
  useSaveable(data);
  
  return (
    <PageWrapper>
      <PageTitleLine>
        <PageTitle>
          <DocumentTitle
            value={data.data.title}
            update={v => data.update({ title: v })}
            />
        </PageTitle>
        {data.isLoading && <LoadingIndicator />}
        {data.hasChanges && <Blip onClick={() => data.save()} />}
      </PageTitleLine>
      
      <PageContent1x1>
        
        <PageMainColumn extraBottomPadding>
          <CurrentDocumentProvider document={data.data}>
            {data.data._id &&
              <SpellbookEditor
                key={data.data._id}
                content={data.data.content}
                update={v => data.update({ content: v })}
                knowledgeBase
                />}
          </CurrentDocumentProvider>
          

          
          
        </PageMainColumn>
      </PageContent1x1>

    </PageWrapper>
  );
}
