import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  useHistory,
} from "react-router-dom";
import { Container } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { Routes, getRoutesToRender, AppContentSimple } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { SpaceContextProvider } from './components/Spaces/SpaceContext';
import { ThemeSettings } from './theme';
import { SpaceSettingsDialog } from './components/Spaces/SpaceSettingsDialog';
import { useDialogState } from './toolympus/components/primitives';
import { AppActionsProvider } from './AppActionsProvider';
import { SpaceMediaLibProvider } from './SpaceMediaLibProvider';
import { AppMenu } from './AppMenu';



const AppView = styled(Container)<{ limitHeight?: boolean, isMenuOpen?: boolean }>`
  min-height: 100vh;
  display: flex !important;
  max-width: 100vw;
  padding-left: ${props => (props.isMenuOpen ? ThemeSettings.sizes.menu.width.expanded : ThemeSettings.sizes.menu.width.collapsed) + ThemeSettings.sizes.gutter.main_horizontal}px;
  padding-right: 24px;
  position: relative;
  background: url("/img/bg_squares_even_x.png"),
    linear-gradient(90deg, rgb(230, 232, 235) 0%, rgb(240, 244, 250) 30%);

  ${props => props.theme.breakpoints.up('lg')} {
      ${props => props.limitHeight ?  "max-height: 100vh;" : ""}
  }
  transition: padding 0.35s ease;

  ${AppContentSimple} {
    background-color: transparent;
    & *::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: transparent;
    }
    & *::-webkit-scrollbar-thumb {
      background-color: transparent;
      width: 4px;
      border-top: 16px solid #00000000;
      border-bottom: 16px solid #00000000;
      background-clip: padding-box;
    }
    & *:hover::-webkit-scrollbar-thumb {
      background: linear-gradient(90deg, ${ThemeSettings.colors.scrollbar} 0%, ${ThemeSettings.colors.scrollbar} 30%, #00000000 50%, #00000000);
      background-clip: padding-box;
    }
  }

  @media(max-width: 1280px){
      max-width: 100vw;
      display: flex;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    padding-left: 12px;
    padding-right: 12px;
  }
`;



const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });
    const spaceSettingsDialog = useDialogState();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <SpaceContextProvider>
            <SpaceMediaLibProvider>
              <AppActionsProvider>

                <AppView disableGutters maxWidth={false} isMenuOpen={isMenuOpen}>
                  <AppMenu
                    menuItems={menuItems}
                    logout={logout}
                    openSettings={spaceSettingsDialog.open}
                    isOpen={isMenuOpen}
                    setIsOpen={setIsMenuOpen}
                    />

                  <AppContentSimple fit100Height>
                    <Routes routes={routes} notFoundRedirect="/projects" />
                  </AppContentSimple>
                </AppView>
                
                <SpaceSettingsDialog isOpen={spaceSettingsDialog.isOpen} close={spaceSettingsDialog.close} />
              </AppActionsProvider>
            </SpaceMediaLibProvider>
          </SpaceContextProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

