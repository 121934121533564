import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Dialog, LoadingIndicator, useVisibilityTrigger } from '../../toolympus/components/primitives';
import { useTasksCalendar } from './useDueSoonTasks';
import { Typography } from '@mui/material';
import { TaskDisplay } from './TaskDisplay';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';
import { RefreshOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useAppActions } from '../../AppActionsProvider';
import { Task } from './typings';

const DateTitle = styled(Typography)<{ highlight?: boolean, dim?: boolean }>`
  border-bottom: 2px solid ${props => props.highlight ? props.theme.palette.primary.main : "#e0e0e0"};
  margin: 1rem 0 0.5rem;
  font-weight: 500;
  opacity: ${props => props.dim ? 0.5 : 1};
`;

const TasksWrapper = styled.div<{ dim?: boolean }>`
  display: flex;
  flex-flow: column;
  gap: 8px;
  opacity: ${props => props.dim ? 0.5 : 1};
`;

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const TasksCalendarPopup = (props: Props) => {
  const data = useTasksCalendar();

  const actions = useAppActions();
  

  useEffect(() => {
    if(props.isOpen) {
      data.ensureLoaded();
      const onTaskUpdated = (t: Task) => {
        data.reload();
      };
      actions.registerTaskUpdatedHandler(onTaskUpdated);
      return () => actions.unregisterTaskUpdatedHandler(onTaskUpdated);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const visibilityTrigger = useVisibilityTrigger({
    onBecameVisible: () => data.showMore(),
  });

  return (
    <Dialog
      dialogTitle="Календарь задач"
      titleActions={<>
        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
        <StrippedIconButton onClick={() => data.reload()}>
          <RefreshOutlined />
        </StrippedIconButton>
      </>}
      isOpen={props.isOpen}
      noFullscreen
      close={() => props.close()}>

      {data.breakdown.map(bucket => (
        <React.Fragment key={bucket.date}>
          <DateTitle dim={bucket.tasks.length === 0} highlight={moment(bucket.date).get("d") === 1}>
            {bucket.date === "overdue"
              ? "Просроченные"
              : moment(bucket.date).format("DD MMMM YYYY, dd")}
          </DateTitle>

          <TasksWrapper dim={bucket.tasks.length === 0}>
            {!bucket.tasks.length && "-"}

            {bucket.tasks.map(t => (
              <TaskDisplay
                task={t}
                noDueDate={bucket.date !== "overdue"}
                showProject
                />
            ))}
          </TasksWrapper>
        </React.Fragment>
      ))}

      {visibilityTrigger.anchor}
    </Dialog>
  );
}
