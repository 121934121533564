import { useMemo } from "react";
import { useNewItem, withActionOnSaveItem } from "../../../toolympus/api/useNewItem";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { Schema } from "../../../toolympus/hooks/useSchema";
import { useSpace } from "../../Spaces/SpaceContext"
import { DocumentSB } from "../useDocument"

const ZeroUuid = "00000000-0000-0000-0000-000000000000";
export const KnowledgeBasedAttachedKind = "kb";

export const DocumentSBSchema: Schema = {
  title: { label: "Название" },
}

const LetterFinder = /[a-zа-я0-9]/i;
const IsLetter = /[a-zа-я]/i;

const getFirstLetter = (s: string) => {
  if(!s) {
    return "0-9";
  }
  const [l] = LetterFinder.exec(s) || [];
  if(l) {
    return l.toLowerCase();
  } else {
    return "0-9";
  }
}

export const useKnowledgeBaseList = () => {
  const { spaceId } = useSpace();
  const data = useLoadedData<DocumentSB[]>(`/api/s/${spaceId}/document/knowledge-base`, [], !!spaceId);

  const newDocument = useNewItem<Partial<DocumentSB>, DocumentSB>(`/api/s/${spaceId}/document`, {
    attached_to_kind: KnowledgeBasedAttachedKind,
    attached_to_id: ZeroUuid,
    title: "",
  });

  const filter = useTextFilter<DocumentSB>(d => d.title);

  const [filtered, byLetter] = useMemo(() => {
    const filtered = filter.filterData(data.data);
    const byLetter: Record<string, DocumentSB[]> = {};
    filtered.forEach(d => {
      let l = getFirstLetter(d.title);
      if(!IsLetter.exec(l)) {
        l = "0-9"; 
      }
      byLetter[l] = byLetter[l] || [];
      byLetter[l].push(d);
    });

    const byLetterResult: { items: DocumentSB[], letter: string }[] = [];
    Object.entries(byLetter).forEach(([letter,items]) => {
      items.sort((a,b) => a.title < b.title ? -1 : 1);
      byLetterResult.push({ letter, items });
    });
    byLetterResult.sort((a,b) => a.letter < b.letter ? -1 : 1);

    return [filtered, byLetterResult];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.filter, data.data]);

  return {
    ...data,
    data: filtered,
    filter,
    byLetter,
    newDocument: withActionOnSaveItem(newDocument, r => { data.reload(); }),
  }
}
