import { useMemo, useState } from "react";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { useSpace } from "../Spaces/SpaceContext"
import { Task } from "./typings"
import { DateInternalFormat, today } from "../../toolympus/api/datetimeUtil";
import { todayMoment } from "./DueDate";

export const useDueSoonTasks = () => {
  const { spaceId } = useSpace();
  const data = useLoadedData<Task[]>(`/api/s/${spaceId}/task/due-soon?before=2`, [], !!spaceId);

  const breakdown = useMemo(() => {
    const todayX = today();
    const overdue = data.data.filter(t => t.due_date && t.due_date < todayX);
    const dueToday = data.data.filter(t => t.due_date && t.due_date === todayX);
    const dueSoon = data.data.filter(t => t.due_date && t.due_date > todayX);

    return {
      overdue: overdue.length,
      today: dueToday.length,
      soon: dueSoon.length,
    }
  }, [data.data]);

  return {
    ...data,
    breakdown,
  }
}

interface CalendarBucket {
  date: string;
  tasks: Task[];
}

export const useTasksCalendar = () => {
  const [doLoad, setDoLoad] = useState<boolean>(false);
  const { spaceId } = useSpace();
  const data = useLoadedData<Task[]>(`/api/s/${spaceId}/task/due-soon`, [], !!spaceId && !!doLoad);

  const [showUntil, setShowUntil] = useState<number>(7);

  const breakdown = useMemo(() => {
    const buckets: CalendarBucket[] = [];
    const todayX = today();
    const overdue = data.data.filter(t => t.due_date && t.due_date < todayX);

    if(overdue.length) {
      buckets.push({ date: "overdue", tasks: overdue });
    }

    Array.from({ length: showUntil + 1}).forEach((_,n) => {
      const d = todayMoment().add(n, "d").format(DateInternalFormat);
      buckets.push({ date: d, tasks: data.data.filter(t => t.due_date === d )});
    })

    return buckets;
  }, [data.data, showUntil]);

  return {
    ...data,
    breakdown,
    showUntil,
    setShowUntil,
    showMore: () => !!doLoad && !data.isLoading && setShowUntil(x => x+7),
    ensureLoaded: () => setDoLoad(true),
  }
}
