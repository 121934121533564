import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const ThemeSettings = {
  colors: {
    text: "#202030",
    textSecondary: "#20203080",
    textOnDark: "#ffffff",
    primary: "#C43337",
    primary_shade: {
      p020: "#C4333733",
      p014: "#C4333723",
      p012: "#C433371e",
    },
    secondary: "#193354",
    table: {
      border: "#20203010",
      editor_border: "#20203040",
    },
    backgroundDim: "#00000020",
    backgroundAppGradientStop1: "rgb(230, 232, 235)",
    scrollbar: "#20203020",
    background: "#ffffff",
    shadow: "#20203080",

    chipDefaultBackground: "#e8e8e8",

    semantic: {
      resolved: "#14aa14",
    },
  },

  typography: {
    fontFamily: "NotoSans",
    fontFamilyHeaders: "NotoSans",
    fontSizes: {
      tiny: "0.7rem",
      smaller: "0.9rem",
      h2: "2rem",
      h3: "1.6rem",
      h4: "1.2rem",
    }
  },

  shadows: {
    panel: "0px 1px 10px -5px",
  },

  zIndex: {
    tray: 500,
    menu: 1000,
  },

  sizes: {
    menu: {
      width: {
        collapsed: 40,
        expanded: 240,
        pad: 20,
      },
    },

    gutter: {
      main_horizontal: 24,
    },
  },
}

export const theme = createTheme({
    palette: {
        text: { primary: ThemeSettings.colors.text },
        primary: {
            main: ThemeSettings.colors.primary,
        },
        secondary: {
            main: ThemeSettings.colors.secondary,
        },
    },

    typography: {
      fontFamily: ThemeSettings.typography.fontFamily,
      h1: { fontWeight: 400 },
      h2: { fontWeight: 400 },
      h3: { fontWeight: 400 },
      h4: { fontWeight: 400 },
  },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
