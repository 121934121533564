import React, { useState } from "react";
import { Editor } from "slate";
import { CustomElement } from "../../slate";
import { useDialogState } from "../../toolympus/components/primitives";
import { createWithVoidInjector } from "../../toolympus/components/PowerDoc/plugins/common";
import { DescriptionOutlined } from "@mui/icons-material";
import { NestedDocumentBlock, NestedDocumentLinkBlock, NestedDocumentBlockType } from "./NestedDocumentBlock";
import { InsertNestedDocumentDialog } from "./InsertNestedDocumentDialog";
import { useCurrentDocument, useDocumentsContext } from "./DocumentsContext";
import { DocumentSB } from "./useDocument";
import { KnowledgeBasedAttachedKind } from "./KnowledgeBase/useKnowledgeBaseList";

const withNestedDocuments = createWithVoidInjector(NestedDocumentBlockType);

export const useNestedDocumentsEditorPlugin = (knowledgeBase?: boolean) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const dialogState = useDialogState();
  const documents = useDocumentsContext();
  const currentDocument = useCurrentDocument();

    const insertDocument = (editor: Editor, documentId: string) => {
        editor.insertNode({
            type: NestedDocumentBlockType,
            document_id: documentId,
            children: [{ text: "", }],
        } as CustomElement);
    }

    const createDocument = (editor: Editor, title: string) => {
        setIsCreating(true);
        const docData: Partial<DocumentSB> = { title };
        if(knowledgeBase) {
          docData.attached_to_kind = KnowledgeBasedAttachedKind;
        }
        return documents.createDocument(docData, currentDocument)
            .then(doc => {
                insertDocument(editor, doc._id);
                setIsCreating(false);
                return doc;
            });
    }

    return {
        key: "nested-documents",
        customBlocks: { [NestedDocumentBlockType]: knowledgeBase ? NestedDocumentLinkBlock : NestedDocumentBlock },
        inject: withNestedDocuments,
        dialogs: (
            <InsertNestedDocumentDialog
                state={dialogState}
                createDocument={createDocument}
                isSaving={isCreating} 
                />),
        commands: [{
            name: "nested-document",
            invoke: () => dialogState.open(),
            menu: {
                section: "insert-item",
                label_id: "documentation.create_doc.insert",
                label: "Раздел",
                icon: <DescriptionOutlined />,
            }
        }]
    };
}
