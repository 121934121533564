import { useCrudItem } from "../../../toolympus/api/useSimpleCrud"
import { useSpace } from "../../Spaces/SpaceContext";
import { DocumentSB } from "../useDocument";

export const useKnowledgeBaseItem = (id: string) => {
  const { spaceId } = useSpace();
  const data = useCrudItem<DocumentSB>(`/api/s/${spaceId}/document/${id}`, {
    defaultValue: { _id: "", title: "" },
    noLoad: !id || !spaceId,
  });

  return {
    ...data,
  }
}
