import React from 'react';
import { PowerEditorBase, PowerEditorBaseProps } from '../../toolympus/components/PowerDoc';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { useNestedDocumentsEditorPlugin } from './useNestedDocumentsEditorPlugin';
import { CustomizedColumnsPlugin } from './EditorCustomizations/ColumnsPlugin';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';
import { FancyShortcutsPlugin } from './EditorCustomizations/FancyShortcutsPlugin';
import { PowerEditorSettingsProvider } from '../../toolympus/components/PowerDoc/PowerEditorSettingsContext';

interface Props extends PowerEditorBaseProps {
  knowledgeBase?: boolean;
}

export const SpellbookEditorUnwrapped = (props: Props) => {
  const mediafiles = useMediaFilesPlugin();
  const nestedDocuments = useNestedDocumentsEditorPlugin(props.knowledgeBase);

  return (
    <PowerEditorBase
      content={props.content}
      update={props.update}
      viewMode={props.viewMode}
      plugins={[
        mediafiles,
        CustomizedColumnsPlugin,
        nestedDocuments,
        TablesPlugin,
        FancyShortcutsPlugin,
      ]}
      />
  );
}

export const SpellbookEditor = (props: Props) => {
  return (
    <PowerEditorSettingsProvider EditorComponent={SpellbookEditorUnwrapped}>
        <SpellbookEditorUnwrapped {...props} />
    </PowerEditorSettingsProvider>
)
}

