import { Autocomplete, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useProjectsContext } from './ProjectsContext';
import { LoadingIndicator } from '../../toolympus/components/primitives';
import { ThemeSettings } from '../../theme';
import { Link } from 'react-router-dom';
import { CallMadeOutlined } from '@mui/icons-material';

interface ProjectLabelProps {
  unfilled?: boolean;
  withLink?: boolean;
}


const ProjectLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;


const ProjectLabel = styled(Typography)<ProjectLabelProps>`
  margin: 0;
  padding: 1px 5px;
  border-radius: 6px;
  background-color: ${props => props.unfilled ? "transparent" : ThemeSettings.colors.chipDefaultBackground};
  border: 1px solid ${props => props.unfilled ? ThemeSettings.colors.chipDefaultBackground : "transparent"};
  cursor: pointer;
  font-size: 0.75em;
  line-height: 1.3;
  position: relative;

  & ${ProjectLink} {
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(2px) translateY(-50%);
  }
`;
ProjectLabel.defaultProps = { className: "project-id-editor" };


interface Props {
  project_id: string | undefined | null;
  update: (v: string | undefined | null) => void;
}

export const ProjectPicker = (props: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { projects, ensureLoaded, getProjectTitle } = useProjectsContext();

  useEffect(() => {
    ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if(!isEditing) {
    if(!props.project_id) {
      <ProjectLabel onClick={() => setIsEditing(true)}>выбрать проект</ProjectLabel>
    } else {
      return (
        <ProjectLabel onClick={() => setIsEditing(true)}>
          {getProjectTitle(props.project_id) || <LoadingIndicator sizeVariant="s" />}
          {props.project_id &&
            <ProjectLink to={`/projects/${props.project_id}`} onClick={e => { e.stopPropagation(); }}>
              <CallMadeOutlined fontSize="inherit" />
            </ProjectLink>}
        </ProjectLabel>
      );
    }
  }

  return (
    <Autocomplete
      // disablePortal
      autoHighlight
      fullWidth
      size="small"
      options={projects.map(p => p.project_id)}
      value={props.project_id || ""}
      onChange={(e,newValue) => { props.update(newValue); }}
      getOptionLabel={getProjectTitle}
      noOptionsText="..."
      onBlur={() => setIsEditing(false)}
      renderInput={(params) => <TextField {...params} size="small" autoFocus margin="none" label="" />}

      />
  );
}

export const ProjectDisplay = (props: { project_id: string } & ProjectLabelProps) => {
  const { ensureLoaded, getProjectTitle } = useProjectsContext();

  useEffect(() => {
    ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!props.project_id) {
    return null;
  }

  const main = (
    <ProjectLabel {...props}>
      {getProjectTitle(props.project_id)}
    </ProjectLabel>
  )

  return props.withLink && !!props.project_id
    ? <ProjectLink to={`/projects/${props.project_id}`} onClick={e => { e.stopPropagation(); }}>
      {main}
    </ProjectLink> 
    : main;
}