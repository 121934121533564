import React, { useEffect } from 'react';
import { useSpace } from './SpaceContext';
import { Schema } from '../../toolympus/hooks/useSchema';
import { Buttons, FormGrid, LoadingIndicator, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useEditItem2 } from '../../toolympus/api/useNewItem';
import { Button } from '@mui/material';
import { useAction } from '../../toolympus/api/useAction';
import { apiFetch } from '../../toolympus/api/core';

interface Props {
  
}

const IntegrationSettingsSchema: Schema = {
  owner_tg_user: { label: "Telegram User ID", hint: "User ID можно узнать у бота SpellbookBot" },
}

const useIntegrationSettings = () => {
  const { settings, spaceId } = useSpace();

  const editor = useEditItem2<Record<string, any>>({
    save: (item) => {
      return settings.update({ ...item });
    },
    dontResetOnSave: true,
  });

  useEffect(() => {
    editor.startEditing(settings.settings || {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.settings]);

  const testTgIntegration = useAction(() => {
    return apiFetch(`/api/s/${spaceId}/task/test-due-today-tg`, "post", {});
  }, !!editor.item?.owner_tg_user && !editor.hasChanges);

  return {
    ...editor,
    testTgIntegration,
  }
}

export const IntegrationsSettings = (props: Props) => {
  const data = useIntegrationSettings();
  
  return (
    <FormGrid columns="1fr 100px" alignItems="center">
      <FormControlsForFields
        data={data.item || {}}
        onChange={(o,c) => data.update(c)}
        schema={IntegrationSettingsSchema}
        fields={[
          ["owner_tg_user"]
        ]}
        />

      <Button
        size="small"
        disabled={!data.testTgIntegration.canRun}
        startIcon={data.testTgIntegration.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
        onClick={() => data.testTgIntegration.run()}>
        тест
      </Button>
      
      {data.hasChanges &&
        <Buttons>
          <OccupyFreeSpace />
          <Button size="small" color="primary" variant="contained" onClick={() => data.save()}>сохранить</Button>
        </Buttons>}
    </FormGrid>
  );
}
