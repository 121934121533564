import React, { DragEvent } from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';
import { ArrowDownward, ArrowUpward, Check } from '@mui/icons-material';
import { Task } from './typings';
import { useAppActions } from '../../AppActionsProvider';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';
import { Buttons, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { DueDateDisplay } from './DueDate';
import { ProjectDisplay } from '../Projects/ProjectPicker';

export const ResolvedMarker = styled.span`
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 24px;
  
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  
  background: ${ThemeSettings.colors.semantic.resolved};
  color: #ffffff;
  font-size: 0.7rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  & .MuiSvgIcon-root {
    font-size: inherit;
  }
`;

export const ResolvedMarkerX = () => {
  return <ResolvedMarker>
    <Check />
  </ResolvedMarker>
}

interface TaskItemProps {
  isDragged?: boolean;
  isDragTarget?: boolean;
  isResolved?: boolean;
  condensed?: boolean;
}

const TaskActionButtons = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1px;
  
  position: absolute;
  top: 0;
  right: 100%;
  width: 12px;
  background-color: transparent;
  padding-right: 2px;

  ${StrippedIconButton} {
    font-size: 0.24rem;
    padding: 2px;
    & > svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const TaskItem = styled.div<TaskItemProps>`
  padding: ${props => props.condensed ? 3 : 6}px ${props => props.condensed ? 8 : 12}px;
  padding-right: ${props => props.isResolved ? 28 : props.condensed ? 8 : 12}px;
  line-height: 1.25;
  font-size: ${props => props.condensed ? ThemeSettings.typography.fontSizes.smaller : "inherit"};
  border-radius: 8px;
  box-shadow: 0px 2px 1px -2px rgba(0,0,0,0.2),
    0px 1px 2px 0px rgba(0,0,0,0.14),
    0px 0px 5px 0px rgba(0,0,0,0.12);
  cursor: pointer;
  opacity: ${props => props.isDragged ? 0.5 : 1};
  position: relative;
  background-color: ${ThemeSettings.colors.background};

  margin-top: ${props => props.isDragTarget && !props.isDragged ? 8 : 0}px;

  &:before {
    display: ${props => props.isDragTarget ? "block" : "none"};
    position: absolute;
    content: '';
    top: -7px;
    left: 0;
    width: 100%;
    height: 0px;
    border-bottom: 4px solid ${ThemeSettings.colors.table.editor_border};
  }

  & ${TaskActionButtons} {
    display: none;
  }
  &:hover {
    & ${TaskActionButtons} {
      display: flex;
    } 
  }

  & .extras {
    margin-top: 2px;
  }
`;

export const TaskItemCreator = styled(TaskItem)`
  box-shadow: 0px 2px 1px -2px rgba(0,0,0,0.2),
    0px 1px 2px 0px rgba(0,0,0,0.14),
    0px 0px 5px 0px ${ThemeSettings.colors.primary_shade.p012};

  & textarea {
    width: 100%;
    resize: none;
    font-size: inherit;
    font-family: inherit;
    border: none;
    outline: none;
  }
`;

export const TaskItemPlaceholder = styled(TaskItem)<{ fullOpacity?: boolean }>`
  text-align: center;
  font-size: ${ThemeSettings.typography.fontSizes.tiny};
  opacity: ${props => props.isDragTarget || props.fullOpacity ? 1 : 0.5};
  color: ${props => props.isDragTarget ? "transparent" : "inherit"};
  background-color: ${props => props.isDragTarget ? "transparent" : ThemeSettings.colors.background};
  margin-top: 0px;

  &:before {
    display: none;
  }
`;



export const TaskListWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 10px;
  
`;



interface TaskDisplayProps {
  task: Task;
  draggedTask?: Task | null;
  dragTargetTask?: Task | null;
  onDrop?: (dropped: Task) => void;
  setDragTargetTask?: (t: Task | null) => void;
  setDraggedTask?: (t: Task | null) => void;
  onDragStart?: (e: DragEvent) => void;
  condensed?: boolean;
  reorderTo?: (t: Task, position: "top" | "bottom") => void;
  noDueDate?: boolean;
  showProject?: boolean;
}

export const TaskDisplay = (props: TaskDisplayProps) => {
  const { task: t, draggedTask, dragTargetTask, setDragTargetTask, setDraggedTask, onDrop } = props;
  const actions = useAppActions();

  return (
    <TaskItem
      key={t._id}
      isResolved={!!t.resolved_at}
      condensed={props.condensed}
      draggable={setDraggedTask ? true : false}
      isDragged={t === draggedTask}
      isDragTarget={t === dragTargetTask}
      onDragStart={e => {
        if(props.onDragStart) {
          props.onDragStart(e);
        }
        if(setDraggedTask) {
          e.dataTransfer.setData("text/plain", `task:${t._id}`);
          setDraggedTask(t);
        }
      }}
      onDragEnter={e => { e.preventDefault(); if(setDragTargetTask) { setDragTargetTask(t) } }}
      onDragOver={e => { e.preventDefault(); if(setDragTargetTask) { setDragTargetTask(t) } }}
      onDragLeave={e => { e.preventDefault(); if(setDragTargetTask) { setDragTargetTask(null) } }}
      onDrop={onDrop
        ? e => {
          e.preventDefault();
          onDrop(t);
        }
        : undefined}
      onClick={() => actions.openTask(t._id)}>

      {!!props.reorderTo &&
        <TaskActionButtons>
          <StrippedIconButton onClick={e => { if(props.reorderTo) { e.stopPropagation(); props.reorderTo(t, "top"); }}}><ArrowUpward /></StrippedIconButton>
          <StrippedIconButton onClick={e => { if(props.reorderTo) { e.stopPropagation(); props.reorderTo(t, "bottom"); }}}><ArrowDownward /></StrippedIconButton>
        </TaskActionButtons>}

      {t.title}
      {((t.due_date && !props.noDueDate) || props.showProject) &&
        <Buttons className="extras">
          {props.showProject && t.project_id && <ProjectDisplay project_id={t.project_id} withLink />}
          <OccupyFreeSpace />
          {t.due_date && !props.noDueDate && <DueDateDisplay date={t.due_date} />}
        </Buttons>}
      {!!t.resolved_at && <ResolvedMarkerX />}
    </TaskItem>
  )
}