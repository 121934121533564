import React from "react";
import { Global, css } from "@emotion/react";
import { ThemeSettings } from "../../theme";

const globalStyle = css`
  html, body {
    margin: 0;
    font-size: 16px;
    line-height: 1.3;
  }
  


  * {
    font-family: ${ThemeSettings.typography.fontFamily};
  }


  * ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
  }
  * ::-webkit-scrollbar-thumb {
      background-color: ${ThemeSettings.colors.primary};
  }
`;

export const GlobalStyles = () => <Global styles={globalStyle} />
