import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected } from 'slate-react';
import { DescriptionOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { LoadingIndicator } from '../../toolympus/components/primitives';
import { useDocumentsContext } from './DocumentsContext';
import { useAppActions } from '../../AppActionsProvider';
import { Link } from 'react-router-dom';


const DocumentWrapper = styled.a<{ isSelected?: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.5rem;
    background: ${props => props.isSelected ? `${props.theme.palette.primary.main}20` : "transparent"};
    text-decoration: none;
    color: inherit;
    cursor: pointer;
`;

const DocumentWrapperLink = styled(Link)<{ isSelected?: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.5rem;
    background: ${props => props.isSelected ? `${props.theme.palette.primary.main}20` : "transparent"};
    text-decoration: none;
    color: inherit;
    cursor: pointer;
`;

export const NestedDocumentBlockType = "sb_nested_document";

export const NestedDocumentBlockBase = (props: RenderElementProps & { makeLink?: boolean }) => {
  const { element, attributes, children } = props;
  const documents = useDocumentsContext();
  const isSelected = useSelected();

  const actions = useAppActions();

  const documentId = (element as any).document_id || "";

  useEffect(() => {
    if(documentId && !documents.documentsTitles[documentId]) {
      documents.ensureDocumentsTitles([documentId]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, documents.documentsTitles]);

  return (
      <div contentEditable={false} {...attributes}>
          {children}

          {props.makeLink
            ? (
              <DocumentWrapperLink to={`/kb/${documentId}`} isSelected={isSelected}>
                <DescriptionOutlined color="action" /> <Typography variant="h6">{documents.documentsTitles[documentId] || <LoadingIndicator sizeVariant="font" />}</Typography>
              </DocumentWrapperLink>
            )
            : (
              <DocumentWrapper isSelected={isSelected} onClick={() => documentId && actions.openDocument(documentId)}>
                  <DescriptionOutlined color="action" /> <Typography variant="h6">{documents.documentsTitles[documentId] || <LoadingIndicator sizeVariant="font" />}</Typography>
              </DocumentWrapper>
            )}
      </div>
  );
}

export const NestedDocumentBlock = (props: RenderElementProps) => {
  return <NestedDocumentBlockBase {...props} />
}

export const NestedDocumentLinkBlock = (props: RenderElementProps) => {
  return <NestedDocumentBlockBase makeLink {...props} />
}