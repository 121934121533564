import { useState } from "react"

export interface PartialPosition {
  top: number | undefined;
  bottom: number | undefined;
  left: number | undefined;
  right: number | undefined;
}

export const useDragHelperTrayPosition = () => {
  const [position, setPosition] = useState<PartialPosition | null>(null);

  const place = (originX: number, originY: number) => {
    let top: number | undefined = undefined;
    let bottom: number | undefined = undefined;
    let left: number | undefined = undefined;
    let right: number | undefined = undefined;

    if(originX - 200 - 25 > 0) {
      right = originX - 25;
    } else {
      left = originX + 25;
    }
    if(originY + 200 > window.innerHeight) {
      bottom = originY + 25;
    } else {
      top = originY + 25;
    }

    setPosition({ top, bottom, left, right });
  }

  const close = () => {
    setPosition(null);
  }

  return {
    position,
    place,
    close,
  }
}
