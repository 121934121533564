import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTaskEdit } from './useTaskEdit';
import { SpellbookEditor, DocumentTitle, CurrentDocumentProvider } from '../Documents';
import { Buttons, FormGrid, LoadingIndicator, useCopyText, useWindowHotkey } from '../../toolympus/components/primitives';
import { Blip } from '../Common/PageComponents';
import { ThemeSettings } from '../../theme';
import { Check, Link } from '@mui/icons-material';
import isHotkey from 'is-hotkey';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';
import { DueDateEditor } from './DueDate';
import { ProjectPicker } from '../Projects/ProjectPicker';

export const DialogTitleX = styled(DialogTitle)<{ isResolved?: boolean }>`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 6px;
  background: ${props => props.isResolved
    ? `url("/img/bg_squares_even_x.png"),
      linear-gradient(90deg, rgb(230, 232, 235) 0%, rgb(240, 244, 250) 30%);`
    : "transparent"};

  & input {
    width: 100%;
  }

  line-height: 1.2;
  
`;

const ResolvedButton = styled.button<{ isVisible?: boolean }>`
  background: ${ThemeSettings.colors.semantic.resolved};
  color: #ffffff;
  padding: 3px 6px 1px;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 8px;

  opacity: ${props => props.isVisible ? 1 : 0};

  &:hover {
    opacity: ${props => props.isVisible ? 0.25 : 0.5};
  }
`;

const TaskHeader = styled(FormGrid)`
  padding: 1rem 24px;
`;

const DialogContentX = styled(DialogContent)`
  mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10px);
  mask-size: 100% 100%;
`;

interface Props {
  taskId: string | null;
  close: () => void;
}

const eventOutsideInputs = (e: KeyboardEvent) => {
  return !(!e.target || (e.target as any).tagName === "INPUT" || (e.target as any ).role === "textbox");
}

export const TaskEditPopup = (props: Props) => {
  const { task, description, hasChanges, save, resolve } = useTaskEdit(props.taskId || "");

  useWindowHotkey("alt+s", () => { if(hasChanges) { save() }});
  useWindowHotkey("alt+r", () => { resolve("toggle"); });

  const copyText = useCopyText();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if(isHotkey("e", e) && eventOutsideInputs(e)) {
        const title = document.querySelector(".task-title");
        if(title && (title as any).click) {
          (title as any).click();
          e.preventDefault();
        }
      } else if(isHotkey("d", e) && eventOutsideInputs(e)) {
        const dueDate = document.querySelector(".due-date-editor");
        if(dueDate && (dueDate as any).click) {
          (dueDate as any).click();
          e.preventDefault();
        }
      } else if(isHotkey("p", e) && eventOutsideInputs(e)) {
        const projectEditor = document.querySelector(".project-id-editor");
        if(projectEditor && (projectEditor as any).click) {
          (projectEditor as any).click();
          e.preventDefault();
        }
      }
    }
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);

  return (
    <Dialog
      open={!!props.taskId}
      maxWidth="md"
      fullWidth
      onClose={() => props.close()}>
      <DialogTitleX isResolved={!!task.data.resolved_at}>
        {task.data._id === props.taskId &&
          <DocumentTitle
            value={task.data.title}
            update={v => task.update({ title: v })}
            spanClassName="task-title"
            />}

        <Buttons gap="small">
          {task.isLoading && <LoadingIndicator sizeVariant="s" />}
          <ResolvedButton
            tabIndex={-1}
            isVisible={!!task.data.resolved_at}
            onClick={() => resolve("toggle")}>
            <Check />
          </ResolvedButton>
          {!task.isLoading && <StrippedIconButton onClick={() => copyText(window.location.href)}>
            <Link />
          </StrippedIconButton>}
          {hasChanges && <Blip onClick={() => save()} />}
        </Buttons>
      </DialogTitleX>

        <TaskHeader columns="2fr 1fr" noMargin alignItems="center">
          <ProjectPicker
            project_id={task.data.project_id}
            update={v => task.update({ project_id: v as any })}
            />

          <DueDateEditor
            date={task.data.due_date}
            update={v => task.update({ due_date: v })}
            />
        </TaskHeader>
      <DialogContentX>

        {description.isLoading && <LoadingIndicator sizeVariant="s" />}

        <CurrentDocumentProvider document={description.data}>
          {description.data._id && description.data.attached_to_id === props.taskId &&
            <SpellbookEditor
              content={description.data.content}
              update={v => description.update({ content: v })}
              />}
        </CurrentDocumentProvider>
      </DialogContentX>

      <DialogActions>
        {hasChanges && <Button size="small" color="primary" variant="contained" onClick={() => save().then(() => props.close())}>сохранить</Button>}
      </DialogActions>
    </Dialog>
  );
}
