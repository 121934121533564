import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../theme';
import { ProjectField } from './useProjectsSettings';
import { LoadingIndicator, PseudoLink2, useThrottledState } from '../../toolympus/components/primitives';
import { Project } from './useProjectsList';
import { UnstyledLink } from '../Common/Primitives';
import { Typography } from '@mui/material';
import { TaskDisplay, TaskItemPlaceholder } from '../Tasks/TaskDisplay';
import { LoadedData } from '../../toolympus/hooks/useLoadedData';
import { Task } from '../Tasks/typings';
import { EditProjectAttributeData } from './useEditProjectAttribute';
import { ProjectTagsCondensedDisplay } from './ProjectTagsPicker';
import { FloatingVerticalTray } from '../Common/PageComponents';
import { useDragHelperTrayPosition } from './useDragHelperTrayPosition';


const FieldEditor = styled.div`
  position: relative;
  & textarea {
    width: 100%;
    border: none;
    border-bottom: 1.5px solid ${ThemeSettings.colors.table.editor_border};
    background: transparent;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    resize: none;
  }

  & .spinner {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
  }
`;

const CurrentTasksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 6px;
  
`;

const ProjectRow = styled.tr<{ isDragTarget?: boolean, isDragged?: boolean, isShowingTasks?: boolean }>`
  opacity: ${props => props.isDragged ? 0.5 : 1};
  position: relative;
  & td {
    padding: 12px 6px;
    border-bottom: 1.5px solid ${ThemeSettings.colors.table.border};

    &:first-child {
      /* padding-left: 0; */
    }
    &:last-child {
      padding-right: 0;
    }

  }

  margin-top: ${props => props.isDragTarget && !props.isDragged ? 4 : 0}px;

  & td:first-child:before {
    display: ${props => props.isDragTarget ? "block" : "none"};
    position: absolute;
    content: '';
    top: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 2px solid ${ThemeSettings.colors.table.editor_border};
  }

  & td.current-tasks-column {
    width: ${props => props.isShowingTasks ? "300px" : "0"};
    text-align: ${props => props.isShowingTasks ? "inherit" : "right"};

    & .task-count-blip {
      font-size: ${ThemeSettings.typography.fontSizes.smaller};
      opacity: 0.75;
    }
  }
`;

const ProjectsTable = styled.table`
  width: 100%;

  & thead {
    font-size: ${ThemeSettings.typography.fontSizes.smaller};
    color: ${ThemeSettings.colors.textSecondary};

    & td, & th {
      ${PseudoLink2} {
        margin-left: 0.25rem;
        font-size: ${ThemeSettings.typography.fontSizes.tiny};
      }
    }

    & td.current-tasks-column {
      white-space: nowrap;
    }
  }

  & tr {
    & td:first-child {
      white-space: nowrap;
    }

    & td {
      white-space: pre-line;
    }
  }

  
`;


interface Props {
  showTasks?: boolean;
  setShowTasks?: Dispatch<SetStateAction<boolean>>;
  fields: ProjectField[];
  data: LoadedData<Project[]>;
  editAttributes: EditProjectAttributeData;
  reorderProjectBefore?: (reoderedId: string, beforeId: string) => void;
  currentTasks?: LoadedData<Record<string, Task[]>>;
}

export const ProjectsList = (props: Props) => {
  const {
    showTasks,
    setShowTasks,
    fields,

    data,
    reorderProjectBefore,
    currentTasks,
    editAttributes,
  } = props;

  const [dragging,setDragging] = useThrottledState<Project | null>(null);
  const [dragTarget,setDragTarget] = useThrottledState<Project | null>(null);
  const [bucketDragTarget, setBucketDragTarget] = useState<string | null>(null);
  const reorderHelperTray = useDragHelperTrayPosition();
  useEffect(() => {
    if(!dragging) {
      reorderHelperTray.close();
      setBucketDragTarget(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragging]);


  return (
    <ProjectsTable>
      <thead>
        <tr>
          <td></td>
          {fields.map(f => (
              <td key={f.key}>
                {f.title || "..."}
              </td>
            ))}

          {!!currentTasks && <td className="current-tasks-column">
            Текущие задачи
            <PseudoLink2 onClick={() => setShowTasks && setShowTasks(x => !x)}>
              {showTasks ? "скрыть" : "показать"}
            </PseudoLink2>  
          </td>}
        </tr>
      </thead>
      <tbody>
        {data.data.map(p => (
          <ProjectRow
            key={p.project_id}
            isShowingTasks={showTasks}
            draggable
            isDragged={p === dragging}
            isDragTarget={p === dragTarget}
            onDragStart={e => { setDragging(p); reorderHelperTray.place(e.clientX, e.clientY) }}
            onDragEnter={e => { e.preventDefault(); setDragTarget(p); }}
            onDragOver={e => { e.preventDefault(); setDragTarget(p); }}
            onDragLeave={e => { e.preventDefault(); setDragTarget(null); }}
            onDrop={() => {
              if(dragging && p !== dragging && reorderProjectBefore) {
                reorderProjectBefore(dragging.project_id, p.project_id);
              }
              setDragging(null);
              setDragTarget(null);
            }}>
            <td>
              <UnstyledLink to={`/projects/${p.project_id}`}>
                <Typography key={p.project_id}>
                  {p.title}
                </Typography>
              </UnstyledLink>
              <ProjectTagsCondensedDisplay tags={p.tags} />
            </td>

            {fields.map(f => (
              <td key={f.key} onClick={() => { editAttributes.startEditing(p.project_id, f.key, (p as any)[f.key] || "") }}>
                {editAttributes.editing?.projectId === p.project_id && editAttributes.editing?.field === f.key
                  ? (
                    <FieldEditor>
                      <textarea
                        autoFocus
                        value={editAttributes.editing.value || ""}
                        onChange={e => editAttributes.update(e.target.value)}
                        onBlur={() => editAttributes.complete()}
                        />
                      {!!editAttributes.editing.isSaving && <LoadingIndicator className="spinner" sizeVariant="s" />}
                    </FieldEditor>
                  )
                  : (p as any)[f.key] || ""}
              </td>
            ))}

            {!!currentTasks && <td className="current-tasks-column">
              {!showTasks && <span className="task-count-blip">{(currentTasks.data[p.project_id] || []).length.toString()}</span>}
              {!!showTasks && !!(currentTasks.data[p.project_id] || []).length
                ? <CurrentTasksWrapper>
                    {currentTasks.data[p.project_id].map(t => (
                      <TaskDisplay
                        key={t._id}
                        task={t}
                        condensed
                        />
                    ))}
                </CurrentTasksWrapper>
                : null}
            </td>}
          </ProjectRow>
        ))}
      </tbody>

      <FloatingVerticalTray
        isVisible={!!dragging && !!reorderHelperTray.position}
        position={reorderHelperTray.position}>
          {[["top", "вверх"], ["bottom", "вниз"]].map(([bucket, label]) => (
            <TaskItemPlaceholder 
              isDragTarget={bucketDragTarget === bucket}
              fullOpacity
              onDragEnter={e => { e.preventDefault(); setBucketDragTarget(bucket); }}
              onDragOver={e => { e.preventDefault(); setBucketDragTarget(bucket);  }}
              onDragLeave={e => { e.preventDefault(); setBucketDragTarget(null); }}
              onDrop={e => {
                if(dragging) {
                  e.preventDefault();
                  if(reorderProjectBefore) {
                    reorderProjectBefore(dragging.project_id, bucket);
                  }
                }
                setDragging(null);
                setDragTarget(null);
              }}>
              {label}
            </TaskItemPlaceholder>
          ))}
      </FloatingVerticalTray>
    </ProjectsTable>
  );
}
