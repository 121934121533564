import React from 'react';
import styled from '@emotion/styled';
import { ProjectTag, TagColorOptions, useProjectsTagsConfiguration } from './useProjectsTagsConfiguration';
import { ItemEditorProps, ItemWrapper, ListEditor } from '../../toolympus/components/primitives/ListEditor';
import { Buttons, FormGrid, OccupyFreeSpace, OptionsColorPicker } from '../../toolympus/components/primitives';
import { Button, TextField } from '@mui/material';

interface Props {
  
}

const TagItemEditor = (props: ItemEditorProps<ProjectTag>) => {
  return <FormGrid columns="1fr 1fr 1fr" noMargin alignItems="flex-end">
    <TextField
      size="small"
      value={props.item.label || ""}
      onChange={e => props.update({ label: e.target.value })}
      />
    <OptionsColorPicker
      value={props.item.color}
      update={v => props.update({ color: v })}
      options={TagColorOptions}
      />
  </FormGrid>
}

const TagWrapper = styled(ItemWrapper)`
  & .MuiInputBase-root {
    margin: 0;
  }
`;


export const ProjectsTagsConfiguration = (props: Props) => {
  const data = useProjectsTagsConfiguration();

  return (
    <>
      <ListEditor
        items={data.item?.tags || []}
        idField="code"
        update={v => { console.log("XV", v); data.update({ tags: v })}}
        createDflt={data.createDefaultTag}
        itemEditor={TagItemEditor}
        itemWrapper={TagWrapper}
        noItemMoveButtons
        />
      
      {data.hasChanges &&
        <Buttons>
          <OccupyFreeSpace />
          <Button size="small" color="primary" variant="contained" onClick={() => data.save()}>сохранить</Button>
        </Buttons>}
    </>
  );
}
