import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { Project } from './useProjectsList';
import { useSpace } from '../Spaces/SpaceContext';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { toMap } from '../../toolympus/api/data';

interface IProjectsContext {
  ensureLoaded: () => void;
  projects: Project[];
  isLoading: boolean;
  getProjectTitle: (id: string) => string;
}

const useProjectsContextData = () => {
  const { spaceId } = useSpace();
  const [doLoad, setDoLoad] = useState<boolean>(false);
  const data = useLoadedData<Project[]>(`/api/s/${spaceId}/project?any-status=t`, [], !!spaceId && !!doLoad)

  const val = useMemo(() => {
    const mapped = toMap(data.data, p => p.project_id, p => p.title);
    return {
      projects: data.data,
      isLoading: data.isLoading,
      ensureLoaded: () => setDoLoad(true),
      getProjectTitle: (id: string) => mapped[id] || "",
    }
  }, [data.data, data.isLoading]);

  return val;
}

const ProjectsContext = React.createContext<IProjectsContext>({
  ensureLoaded: () => {},
  isLoading: true,
  projects: [],
  getProjectTitle: () => "",
});

export const useProjectsContext = () => useContext(ProjectsContext);

export const ProjectsProvider = (props: PropsWithChildren<{}>) => {
  const ctx = useProjectsContextData();

  return (
    <ProjectsContext.Provider value={ctx}>
      {props.children}
    </ProjectsContext.Provider>
  );
}
