import React from 'react';
import { Editor, Range, Element as SlateElement, Transforms } from "slate";
import styled from '@emotion/styled';
import { EditorPlugin } from "../../../toolympus/components/PowerDoc";
import { ReactEditor, RenderElementProps, useSlateStatic } from "slate-react";
import { CustomElement } from '../../../slate';
import { DividerBlockType } from '../../../toolympus/components/PowerDoc/plugins/BasicElements/Divider';
import { ParagraphElementType } from '../../../toolympus/components/PowerDoc/slate/BlockElements';

interface ShortcutOption {
  type: string;
  text?: string;
  value?: string;
  values?: string | string[];
}

const SbSymbolToggle = styled.span`
  cursor: pointer;
  font-size: 1.2rem;
`;
SbSymbolToggle.defaultProps = { role: "button", className: "sb-emoji-toggle "};

const nextValue = (values: string, current: string | null | undefined) => {
  if(!current) {
    return values[0];
  }
  const currentIdx = values.indexOf(current);
  if(currentIdx >= values.length - 1 || currentIdx < 0) {
    return values[0];
  } else {
    return values[currentIdx+1];
  }
}

const updateValue = (editor: Editor, element: any, newValue: string) => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.setNodes(
    editor,
    { sb_value: newValue } as any,
    { at: path },
  );
}

const SymbolToggleElementType = "sb_checkbox_toggle";
const SymbolToggleElement = (props: RenderElementProps) => {
  const editor = useSlateStatic();

  const p = props.element as { sb_value?: string, sb_values?: string };

  return <SbSymbolToggle
    {...props.attributes}
    onClick={() => updateValue(editor, props.element, nextValue(p.sb_values || "?", p.sb_value))}>
      {props.children}{p.sb_value}
  </SbSymbolToggle>;
}

const fancyCheckboxOption = (value?: string): ShortcutOption => ({
  type: SymbolToggleElementType,
  values: ["⬜", "✅"],
  value: value || "⬜",
});
const fancyTrafficLightOption = (value?: string): ShortcutOption => ({
  type: SymbolToggleElementType,
  values: ["🟢", "🟡", "🔴"],
  value: value || "🟢",
});

const Shortcuts: Record<string, ShortcutOption> = {
  "..tl": fancyTrafficLightOption(),
  "..tlg": fancyTrafficLightOption(),
  "..tly": fancyTrafficLightOption("🟡"),
  "..tlr": fancyTrafficLightOption("🔴"),
  "ююед": fancyTrafficLightOption(),
  "ююедп": fancyTrafficLightOption(),
  "ююедн": fancyTrafficLightOption("🟡"),
  "ююедк": fancyTrafficLightOption("🔴"),
  

  "..cb": fancyCheckboxOption(),
  "..cbc": fancyCheckboxOption("✅"),
  "ююси": fancyCheckboxOption(),
  "ююсис": fancyCheckboxOption("✅"),
  "---": {
    type: "text",
    text: "→ ",
  },
  "___": {
    type: DividerBlockType,
  },
}

const withShortcuts = (editor: Editor) => {
  const { insertText, isInline, isVoid, } = editor;

  editor.isInline = (element: CustomElement) => {
    return element.type === SymbolToggleElementType ? true : isInline(element)
  }

  editor.isVoid = (element: CustomElement) => {
    return element.type === SymbolToggleElementType ? true : isVoid(element)
  }

  editor.insertText = text => {
    const { selection } = editor;

    if (text.endsWith(' ') && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range) + text.slice(0, -1)
      const option = Shortcuts[beforeText.toLowerCase()];

      // if(!option) {
      //   const textParts = beforeText.split(/\s+/);
      //   const lastPart = textParts[textParts.length - 1];
      //   if(lastPart) {
      //     option = Shortcuts[lastPart.toLowerCase()];
      //     range.focus.offset = range.anchor.offset - lastPart.length;
      //   }
      // }


      if (option) {
        Transforms.select(editor, range)

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor);
        }

        if(option.type === "text" && option.text) {
          insertText(option.text);
        } else if(option.type === SymbolToggleElementType) {
          editor.insertNode({
            type: SymbolToggleElementType,
            sb_value: option.value || (option.values || "?")[0],
            sb_values: option.values,
            children: [{ text: "", }],
          } as any);
          
          Transforms.move(editor, {
            distance: 1,
            unit: "offset"
          });
          insertText(" ");
        } else if(option.type === DividerBlockType) {
          editor.insertNode({
            type: DividerBlockType,
            children: [{ text: "", }],
          });
          editor.insertNode({
            type: ParagraphElementType,
            children: [{ text: "", }],
          });

          insertText(" ");
        }

        // const newProperties: Partial<SlateElement> = {
        //   type: option,
        // }
        // Transforms.setNodes<SlateElement>(editor, newProperties, {
        //   match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n),
        // })

        // if (type === 'list-item') {
        //   const list: BulletedListElement = {
        //     type: 'bulleted-list',
        //     children: [],
        //   }
        //   Transforms.wrapNodes(editor, list, {
        //     match: n =>
        //       !Editor.isEditor(n) &&
        //       SlateElement.isElement(n) &&
        //       n.type === 'list-item',
        //   })
        // }

        return
      }
    }

    insertText(text);
  }

  // editor.deleteBackward = (...args) => {
  //   const { selection } = editor

  //   if (selection && Range.isCollapsed(selection)) {
  //     const match = Editor.above(editor, {
  //       match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n),
  //     })

  //     if (match) {
  //       const [block, path] = match
  //       const start = Editor.start(editor, path)

  //       if (
  //         !Editor.isEditor(block) &&
  //         SlateElement.isElement(block) &&
  //         block.type !== 'paragraph' &&
  //         Point.equals(selection.anchor, start)
  //       ) {
  //         const newProperties: Partial<SlateElement> = {
  //           type: 'paragraph',
  //         }
  //         Transforms.setNodes(editor, newProperties)

  //         if (block.type === 'list-item') {
  //           Transforms.unwrapNodes(editor, {
  //             match: n =>
  //               !Editor.isEditor(n) &&
  //               SlateElement.isElement(n) &&
  //               n.type === 'bulleted-list',
  //             split: true,
  //           })
  //         }

  //         return
  //       }
  //     }

  //     deleteBackward(...args)
  //   }
  // }

  return editor
}

export const FancyShortcutsPlugin: EditorPlugin = {
  key: "fancy-shortcuts",
  inject: withShortcuts,
  customBlocks: { [SymbolToggleElementType]: SymbolToggleElement },
}
