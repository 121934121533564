import React, { useState } from 'react';
import styled from '@emotion/styled';
import moment, { Moment } from 'moment';
import { ThemeSettings } from '../../theme';
import { DateEdit } from '../../toolympus/components/schemed/TimeEdit';

interface Props {
  date?: string;
  onClick?: () => void;
  className?: string;
}

const Colors: Record<string, string> = {
  danger: "#cc0000",
  warning: "#eab504",
  default: ThemeSettings.colors.chipDefaultBackground,
}
const TextColors: Record<string, string> = {
  danger: "#ffffff",
}


export const DueDateDisplayWrapper = styled.span<{ highlight?: "danger" | "warning" | "default" }>`
  font-size: 0.75em;
  background-color: ${props => props.highlight ? Colors[props.highlight] : Colors.default};
  color: ${props => TextColors[props.highlight || "default"] || ThemeSettings.colors.text};
  padding: 1px 5px;
  border-radius: 6px;
  text-align: center;
  min-width: 45px;
  cursor: pointer;
`;

const FormatFull = "DD.MM.YYYY";
const FormatShort = "DD.MM";

export const startOfDay = (m: Moment) => m.set("m", 0).set("h", 0).set("s", 0).set("ms", 0);
export const todayMoment = () => startOfDay(moment());

export const DueDateDisplay = (props: Props) => {
  const { date } = props;

  if(!date || date === "Invalid date") {
    return null;
  }

  const timeRemaining = -todayMoment().diff(date, "d");
  const todayX = moment();
  const d = moment(date);
  const isThisYear = todayX.get("y") === d.get("y");

  const dueText = timeRemaining === 1
    ? "завтра"
    : timeRemaining === 0
      ? "сегодня"
      : timeRemaining > 0 && timeRemaining < 7
        ? `${timeRemaining}д`
        : timeRemaining < 0
          ? `${-timeRemaining}д назад`
          : "";

  return (
    <DueDateDisplayWrapper
      className={props.className}
      onClick={props.onClick}
      highlight={timeRemaining <= 0 ? "danger" : timeRemaining <= 1 ? "warning" : "default"}>
      {isThisYear
        ? d.format(FormatShort)
        : d.format(FormatFull)}
      {dueText ? `, ${dueText}` : ""}
    </DueDateDisplayWrapper>
  );
}

interface EditorProps {
  date: string | null | undefined;
  update: (v: string | null | undefined) => void;
}

export const DueDateEditor = (props: EditorProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if(!isEditing) {
    if(props.date) {
      return <DueDateDisplay className="due-date-editor" date={props.date} onClick={() => setIsEditing(true)} />
    } else {
      return <DueDateDisplayWrapper className="due-date-editor" onClick={() => setIsEditing(true)}>добавить срок</DueDateDisplayWrapper>
    }
  } else {
    return (
      <DateEdit
        field="due_date"
        row={{ due_date: props.date }}
        onChange={(o,c) => props.update(c.due_date)}
        schema={{ label: " " }}
        extraProps={{ controlProps: { autoFocus: true, InputProps: {onblur: () => setIsEditing(false) }}}}
        />
    )
  }
}