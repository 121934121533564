import React from 'react';
import styled from '@emotion/styled';
import { Buttons, FormGrid, LoadingIndicator, OccupyFreeSpace, SearchField, SimpleDialog, useWindowHotkey } from '../../../toolympus/components/primitives';
import { PageContent1x1, PageMainColumn, PageTitle, PageTitleLine, PageWrapper } from '../../Common/PageComponents';
import { IconButton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { DocumentSBSchema, useKnowledgeBaseList } from './useKnowledgeBaseList';
import { Link, useHistory } from 'react-router-dom';
import { ThemeSettings } from '../../../theme';



const SearchLine = styled(Buttons)`
  justify-content: center;
  padding: 0 25% 1rem;
`;

const SectionTitle = styled(Typography)`
  text-transform: uppercase;
  border-bottom: 2px solid ${ThemeSettings.colors.backgroundAppGradientStop1};
  margin-bottom: 0.5rem;
  padding-bottom: 0rem;
`;
SectionTitle.defaultProps = { variant: "h6" };

const DocumentItem = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-weight: 500;
`;


const DocumentsGrid = styled(FormGrid)`
  & + ${SectionTitle} {
    margin-top: 1rem;
  }
`;

const PageContentX = styled(PageContent1x1)`
  grid-template-rows: max-content 1fr;
  gap: 0;
`;

interface Props {
  
}

export const KnowledgeBaseListPage = (props: Props) => {
  const data = useKnowledgeBaseList();
  useWindowHotkey("alt+n", () => {
    data.newDocument.startEditing();
  });

  const history = useHistory();
  
  return (
    <PageWrapper>
      <PageTitleLine dontGrowTitle>
        <PageTitle>
          Страницы
        </PageTitle>
        
        <OccupyFreeSpace />
        {data.isLoading && <LoadingIndicator />}
        <IconButton size="small" color="primary" onClick={() => data.newDocument.startEditing()}>
          <Add />
        </IconButton>
      </PageTitleLine>
      
      <PageContentX>
        <SearchLine>
          <SearchField
            noButton
            filter={data.filter.filter}
            setFilter={data.filter.setFilter}
            autoFocus
            fullWidth
            doSearch={() => {
              if(data.data.length === 1) {
                history.push(`/kb/${data.data[0]._id}`)
              }
            }}
            />
        </SearchLine>
        
        
        <PageMainColumn extraBottomPadding>

          {data.byLetter.map(section => (
            <React.Fragment key={section.letter}>
              <SectionTitle>{section.letter}</SectionTitle>
          
              <DocumentsGrid noMargin>
                {section.items.map(d => (
                  <DocumentItem key={d._id} to={`/kb/${d._id}`}>
                    {d.title}
                  </DocumentItem>
                ))}
              </DocumentsGrid>
            </React.Fragment>
          ))}
          

          
          
        </PageMainColumn>
      </PageContentX>

      <SimpleDialog
        dialogTitle="Создать страницу"
        saveLabel="создать"
        noFullscreen
        save={() => data.newDocument.save().then(d => history.push(`/kb/${d._id}`))}
        isOpen={data.newDocument.isEditing}
        close={data.newDocument.cancel}
        >
          <FormGrid columns="1fr">
            <FormControlsForFields
              fields={[
                ["title", { controlProps: { autoFocus: true } }]
              ]}
              schema={DocumentSBSchema}
              data={data.newDocument.item}
              onChange={(o,c) => data.newDocument.update(c)}
              />
          </FormGrid>
      </SimpleDialog>
    </PageWrapper>
  );
}
