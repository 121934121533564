import React from 'react';
import { Dialog, DialogState, TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives';
import { ProjectsSettingsEdit } from '../Projects/ProjectsSettingsEdit';
import { ProjectsTagsConfiguration } from '../Projects/ProjectsTagsConfiguration';
import { IntegrationsSettings } from './IntegrationsSettings';

interface Props extends Pick<DialogState, "isOpen" | "close"> {
  
}


export const SpaceSettingsDialog = (props: Props) => {

  const tabs = useTabsState([
    ["projects","Проекты"],
    ["project-tags","Теги проектов"],
    ["integration","Интеграции"],
  ], "projects");

  return (
    <Dialog
      isOpen={props.isOpen}
      close={props.close}
      noFullscreen
      dialogTitle="Настройки">

      <TabsHeader state={tabs} noMargin />

      <TabPanel state={tabs.forTab("projects")}>
        <ProjectsSettingsEdit />
      </TabPanel>


      <TabPanel state={tabs.forTab("project-tags")}>
        <ProjectsTagsConfiguration />
      </TabPanel>


      <TabPanel state={tabs.forTab("integration")}>
        <IntegrationsSettings />
      </TabPanel>

      
    </Dialog>
  );
}
