import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ProjectTag, useProjectsTags } from './useProjectsTagsConfiguration';
import { Buttons } from '../../toolympus/components/primitives';
import { ThemeSettings } from '../../theme';
import { MultiSelect } from '../../toolympus/components/schemed/Select';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';
import { Close } from '@mui/icons-material';

const Wrapper = styled(Buttons)<{ condensed?: boolean }>`
  margin: ${props => props.condensed ? "0.25" : "0.5"}rem 0 ${props => props.condensed ? "0" : "0.5"}rem;
  flex-flow: row wrap;

  & ${StrippedIconButton} {
    padding: 0;
    
    ${props => props.theme.breakpoints.down("sm")} {
      display: none;
    }
  }

`;
Wrapper.defaultProps = { gap: "small", className: "project-tags-editor" };

interface Props {
  tags: string[] | undefined;
  update: (v: string[] | undefined) => void;
}

export const TagDisplayWrapper = styled.button<{ color?: string, unfilled?: boolean, condensed?: boolean }>`
  color: ${ThemeSettings.colors.text};
  background-color: ${props => props.unfilled ? "transparent" : props.color || "transparent"};
  border: 1.5px solid ${props => props.color || "transparent"};
  outline: none;
  padding: ${props => props.condensed ? "1px 4px" : "3px 12px"};
  border-radius: 1rem;
  white-space: nowrap;
  cursor: pointer;
  font-size: ${props => props.condensed ? "0.75rem" : ThemeSettings.typography.fontSizes.smaller};
`;

export const TagDisplay = (props: { tag: ProjectTag }) => {
  return (
    <TagDisplayWrapper color={props.tag.color || undefined}>
      {props.tag.label}
    </TagDisplayWrapper>
  )
}

export const ProjectTagsPicker = (props: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { tags, selectorSchema } = useProjectsTags();

  const tagsSelected = tags.filter(t => (props.tags || []).find(tx => tx === t.code));
  
  if(!isEditing) {
    if(!props.tags?.length) {
      return (
        <Wrapper onClick={() => setIsEditing(true)}>
          <TagDisplayWrapper color="#00000040" unfilled>
            добавить теги
          </TagDisplayWrapper>
        </Wrapper>
      )
    } else {
      return (
        <Wrapper onClick={() => setIsEditing(true)}>
          {tagsSelected.map(t => (
            <TagDisplay key={t.code} tag={t} />
          ))}
        </Wrapper>
      );
    }
  }

  return (
    <MultiSelect
      row={{ tags: props.tags || []}}
      field="tags"
      onChange={(o,c) => props.update(c.tags)}
      schema={selectorSchema}
      />      
  );
}


export const ProjectTagsActivator = (props: Props) => {
  const { tags } = useProjectsTags();

  return (
    <Wrapper>
      {tags.map(t => (
        <TagDisplayWrapper
          key={t.code}
          color={t.color || undefined}
          unfilled={!(props.tags || []).includes(t.code)}
          onClick={e => {
            const isAppend = !!e.shiftKey;
            const selected = props.tags || [];
            props.update(selected.includes(t.code)
              ? isAppend ? selected.filter(tx => tx !== t.code) : []
              : isAppend ? [ ...selected, t.code] : [t.code])
          }}
          >
          {t.label}
        </TagDisplayWrapper>
      ))}

      {!!props.tags?.length && <StrippedIconButton onClick={() => props.update([])}><Close /></StrippedIconButton>}
    </Wrapper>
  )
}

export const ProjectTagsCondensedDisplay = (props: { tags: string[] | undefined }) => {
  const { tags } = useProjectsTags();
  
  const selected = props.tags?.length ? tags.filter(t => props.tags?.includes(t.code)) : [];

  if(!selected.length) {
    return null;
  } else {
    return (
      <Wrapper condensed>
        {selected.map(t => (
          <TagDisplayWrapper key={t.code} color={t.color || undefined} condensed>
            {t.label}
          </TagDisplayWrapper>
        ))}
      </Wrapper>
    )
  }
}
