import styled from '@emotion/styled';
import React from 'react';
import { RenderElementProps, useSelected } from 'slate-react';
import { BlockSelectionCss } from '../../../toolympus/components/PowerDoc/elements/Common';
import { usePowerEditorContext } from '../../../toolympus/components/PowerDoc/slate/PowerEditorContext';
import { usePowerEditorSettings } from '../../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { EditorPlugin, PowerEditorBase } from '../../../toolympus/components/PowerDoc';
import { useColumns } from '../../../toolympus/components/PowerDoc/plugins/Columns/useColumns';
import { Buttons } from '../../../toolympus/components/primitives';
import { ColumnsPlugin } from '../../../toolympus/components/PowerDoc/plugins/Columns';
import { ColumnsBlockElementType } from '../../../toolympus/components/PowerDoc/plugins/Columns/ColumnsBlockElement';
import { StrippedIconButton } from '../../../toolympus/components/primitives/StrippedButtons';
import { ReactComponent as Columns2 } from './columns_2.svg';
import { ReactComponent as Columns3 } from './columns_3.svg';
import { ReactComponent as Columns4 } from './columns_4.svg';
import { ThemeSettings } from '../../../theme';

const Icons = {
  2: Columns2,
  3: Columns3,
  4: Columns4,
}


const Wrapper = styled.div<{ isSelected?: boolean, nColumns: number, viewMode?: boolean }>`
    padding: 0.5rem 0;
    ${props => BlockSelectionCss(props)}
    position: relative;

    & .columns-buttons {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: auto;
      z-index: 200;
      user-select: none;
      opacity: 0;
      color: ${ThemeSettings.colors.textSecondary};

      & svg {
        width: 16px;
        height: 16px;
      }
    }

    & > div:last-child {
        width: 100%;
    }

    & > div:last-child > div:last-child {
        display: grid;
        grid-template-columns: repeat(${props => props.nColumns}, 1fr);
        
        & > * {
            padding: 0 1rem;
            min-width: 0;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            &:not(:last-child) {
                border-right: 3px solid transparent;
            }
        }

        ${props => props.theme.breakpoints.down("sm")} {
          grid-template-columns: 1fr;
          /* row-gap: 3rem; */
          & > * {
            padding: 0;
          }
        }
    }
    

    &:hover, &:focus-within {
      & .columns-buttons {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }

      & > div:last-child > div:last-child {
        & > * {
          &:not(:last-child) {
            border-right: 3px solid ${props => props.viewMode ? "transparent" : "#eeeeee"};

            ${props => props.theme.breakpoints.down("sm")} {
              border-right: 3px solid transparent;
            }
          }
        }
      }
    }
`;

export const ColumnsBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;

    const isSelected = useSelected();

    const { viewMode } = usePowerEditorContext();
    
    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    const columns = useColumns(element);

    return (
        <Wrapper {...attributes} isSelected={isSelected} nColumns={columns.columns_content.length} viewMode={viewMode}>
            {children}

            <div style={{ width: "100%" }} contentEditable={false} key={columns.columns_content.length}>
                {!viewMode && <Buttons gap="small" className="columns-buttons">
                    {[2,3,4].map(n => {
                      const Icon = (Icons as any)[n];
                      return (
                      <StrippedIconButton
                        key={n}
                        onClick={() => columns.updateColumnsNumber(n)}
                        color={columns.columns_content.length === n ? "primary" : "inherit"}
                        >
                        <Icon />
                      </StrippedIconButton>
                    )})}
                </Buttons>}

                <div>
                    {columns.columns_content.map((c, cIdx) => (
                        <EditorComponent
                            key={cIdx}
                            content={c}
                            update={v => columns.updateColumnContent(cIdx, v)}
                            viewMode={viewMode}
                            />
                    ))}
                </div>
            </div>
        </Wrapper>
    );
}

export const CustomizedColumnsPlugin: EditorPlugin = {
  ...ColumnsPlugin,
  customBlocks: { [ColumnsBlockElementType]: ColumnsBlockElement },
}
