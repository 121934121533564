import { useEffect, useMemo } from "react";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useSpace } from "../Spaces/SpaceContext";
import { generateCode } from "../../toolympus/api/data";
import { createSelectSchema } from "../../toolympus/hooks/useSchema";

export interface ProjectTag {
  label: string;
  code: string;
  color?: string | null;
}

export const TagColorOptions = [
  "#cc000028",
  "#ffcb0028",
  "#ff7b0028",
  "#006dcc28",
  "#00cc3028",
  "#c900cc28",
  "#00000014",
];

export const ProjectTagsSettingKey = "_sb_project_tags";

export const useProjectsTagsConfiguration = () => {
  const { settings } = useSpace();
  const tags = useEditItem2<{ tags: ProjectTag[]}>({
    save: (item) => {
      return settings.update({ [ProjectTagsSettingKey]: item.tags })
        .then(x => ({ tags: x[ProjectTagsSettingKey]}));
    },
    dontResetOnSave: true,
  });

  useEffect(() => {
    tags.startEditing({ tags: settings.settings[ProjectTagsSettingKey] || []});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.settings]);

  return {
    ...tags,
    createDefaultTag: () => ({ code: generateCode(), label: "", color: TagColorOptions[TagColorOptions.length - 1] }),
  }
}


export const useProjectsTags = () => {
  const { settings } = useSpace();
  const tags: ProjectTag[] = settings.settings[ProjectTagsSettingKey] || [];

  const selectorSchema = useMemo(() => {
    const tags: ProjectTag[] = settings.settings[ProjectTagsSettingKey] || [];
    return createSelectSchema(tags.map(t => ({ label: t.label, value: t.code })), { label: "Теги"});

  }, [settings.settings])

  return {
    tags,
    selectorSchema,
  }
}
